import { v4 as uuidv4 } from 'uuid';

import {
  ConsumableDateLabel,
  ConsumableType,
  generateConsumable,
} from '@app/api/fixtures/consumables';
import {
  aContentRating,
  aNotRatedContentRating,
} from '@app/api/fixtures/content-ratings';
import { imagesDomain } from '@app/api/fixtures/fixture-domains';
import { anIndustryEventEntry } from '@app/api/fixtures/industry-events';
import { Film } from '@app/api/resources/Film';
import { ContentRating, OptimisedTrailer } from '@app/api/resources/Film';

const someContentWarnings = [
  {
    id: 3,
    name: 'suicide/self harm',
    key: 'suicide_or_self_harm',
  },
  {
    id: 6,
    name: 'graphic sexual content',
    key: 'graphic_sexual_content',
  },
];

export const aFilm: Film = {
  id: 3701,
  slug: 'southland-tales',
  title: 'Southland Tales',
  title_locale: 'en-US',
  original_title: 'Southland Tales',
  year: 2006,
  consumable: null,
  duration: 145,
  stills: {
    small:
      'https://assets-staging.mubi.com/images/film/3701/image-w256.jpg?1582649514',
    medium:
      'https://assets-staging.mubi.com/images/film/3701/image-w448.jpg?1582649514',
    standard:
      'https://assets-staging.mubi.com/images/film/3701/image-w856.jpg?1582649514',
    retina:
      'https://assets-staging.mubi.com/images/film/3701/image-w1280.jpg?1582649514',
  },
  still_focal_point: {
    x: 0.55571,
    y: 0.0321782,
  },
  hd: true,
  average_colour_hex: 'B18867',
  trailer_url:
    'https://trailers-dev.mubicdn.net/3701/t-southland-tales_en_us_1280_720_1488258835.mp4',
  trailer_id: 1173,
  popularity: 442,
  web_url: 'https://mubi.localhost:3500/films/southland-tales',
  genres: ['Sci-Fi', 'Comedy'],
  average_rating: 3.0,
  average_rating_out_of_ten: 5.9,
  number_of_ratings: 4888,
  mubi_release: false,
  should_use_safe_still: false,
  still_url:
    'https://images.mubicdn.net/images/film/3701/cache-38736-1582649514/image-w1280.jpg',
  title_upcase: 'SOUTHLAND TALES',
  critic_review_rating: 4.5,
  short_synopsis:
    'With the U.S. under the threat of nuclear attack, several people’s lives converge. Movie star Boxer plans his next film with the help of porn actress Krysta and policeman Roland. Meanwhile, Marxist revolutionaries, corporations, and government agencies pursue their agendas among a paranoid populace.',
  short_synopsis_html:
    '<p>With the U.S. under the threat of nuclear attack, several people&#8217;s lives converge. Movie star Boxer plans his next film with the help of porn actress Krysta and policeman Roland. Meanwhile, Marxist revolutionaries, corporations, and government agencies pursue their agendas among a paranoid populace.</p>',
  default_editorial:
    'Richard Kelly’s much-anticipated but fiercely lambasted follow-up to Donnie Darko has rapidly achieved modern cult status overwriting the critics baffled by the film’s exuberant, Pynchon-esque satire of a near-future America. 14 years later, the prescience of Kelly’s madcap vision is breathtaking.',
  default_editorial_html:
    '<p>Richard Kelly&#8217;s much-anticipated but fiercely lambasted follow-up to <em>Donnie Darko</em> has rapidly achieved modern cult status overwriting the critics baffled by the film&#8217;s exuberant, Pynchon-esque satire of a near-future America. 14 years later, the prescience of Kelly&#8217;s madcap vision is breathtaking.</p>',
  title_treatment_url: null,
  directors: [
    {
      name: 'Richard Kelly',
      name_upcase: 'RICHARD KELLY',
      slug: 'richard-kelly',
    },
  ],
  historic_countries: ['United States', 'France'],
  optimised_trailers: [
    {
      url: 'https://trailers-dev.mubicdn.net/3701/optimised/240p-t-southland-tales_en_us_1280_720_1488258835.mp4',
      profile: '240p',
    },
    {
      url: 'https://trailers-dev.mubicdn.net/3701/optimised/720p-t-southland-tales_en_us_1280_720_1488258835.mp4',
      profile: '720p',
    },
  ] as OptimisedTrailer[],
  mubi_go_highlighted: false,
  content_rating: aContentRating as ContentRating,
  content_warnings: someContentWarnings,
  portrait_image: '',
  episode: null,
  artworks: [],
  highlighted_industry_event_entry: null,
  award: null,
  press_quote: null,
  star_rating: null,
  cast_members_count: null,
  industry_events_count: null,
  comments_count: null,
};

export const anotherFilm: Film = {
  id: 226295,
  slug: 'bacurau',
  title: 'Bacurau',
  title_locale: 'en-US',
  original_title: 'Bacurau',
  year: 2019,
  consumable: null,
  duration: 131,
  stills: {
    small:
      'https://assets-staging.mubi.com/images/film/226295/image-w256.jpg?1584616206',
    medium:
      'https://assets-staging.mubi.com/images/film/226295/image-w448.jpg?1584616206',
    standard:
      'https://assets-staging.mubi.com/images/film/226295/image-w856.jpg?1584616206',
    retina:
      'https://assets-staging.mubi.com/images/film/226295/image-w1280.jpg?1584616206',
  },
  still_focal_point: {
    x: 0.561281,
    y: 0.316832,
  },
  hd: true,
  average_colour_hex: '6F5449',
  trailer_url:
    'https://trailers-dev.mubicdn.net/226295/t-bacurau_en_us_1382.065_1280_720_1581693625.mp4',
  trailer_id: 40339,
  popularity: 1784,
  web_url: 'https://mubi.localhost:3500/films/bacurau',
  genres: ['Drama', 'Thriller', 'Western'],
  average_rating: 4.2,
  average_rating_out_of_ten: 8.4,
  number_of_ratings: 7624,
  mubi_release: true,
  should_use_safe_still: false,
  still_url:
    'https://images.mubicdn.net/images/film/226295/cache-403714-1584616206/image-w1280.jpg',
  title_upcase: 'BACURAU',
  critic_review_rating: 4.75,
  content_rating: aContentRating as ContentRating,
  short_synopsis:
    'Bacurau, a settlement in rural Brazil, is shaken by its matriarch’s death. But something strange is happening, the water supply has been cut off, and the village has disappeared from satellite maps completely. Under threat from an unknown enemy, Bacurau braces itself for a brutal fight for survival.',
  short_synopsis_html:
    '<p>Bacurau, a settlement in rural Brazil, is shaken by its matriarch&#8217;s death. But something strange is happening, the water supply has been cut off, and the village has disappeared from satellite maps completely. Under threat from an unknown enemy, Bacurau braces itself for a brutal fight for survival.</p>',
  default_editorial:
    'From Kleber Mendonça Filho (Aquarius) and Juliano Dornelles comes a daring, intoxicating blend of neo-Western, siege thriller and political allegory—powerfully resonating with today’s Brazil, where the film was a box-office hit! A winner of the Cannes Jury Prize, starring Udo Kier and Sônia Braga.',
  default_editorial_html:
    '<p>From Kleber Mendonça Filho (<em>Aquarius</em>) and Juliano Dornelles comes a daring, intoxicating blend of neo-Western, siege thriller and political allegory&#8212;powerfully resonating with today&#8217;s Brazil, where the film was a box-office hit! A winner of the Cannes Jury Prize, starring Udo Kier and Sônia Braga.</p>',
  title_treatment_url: null,
  directors: [
    {
      name: 'Kleber Mendonça Filho',
      name_upcase: 'KLEBER MENDONÇA FILHO',
      slug: 'kleber-mendonca-filho',
    },
    {
      name: 'Juliano Dornelles',
      name_upcase: 'JULIANO DORNELLES',
      slug: 'juliano-dornelles',
    },
  ],
  historic_countries: ['Brazil', 'France'],
  optimised_trailers: [
    {
      url: 'https://trailers-dev.mubicdn.net/226295/optimised/240p-t-bacurau_en_us_1382.065_1280_720_1581693625.mp4',
      profile: '240p',
    },
    {
      url: 'https://trailers-dev.mubicdn.net/226295/optimised/720p-t-bacurau_en_us_1382.065_1280_720_1581693625.mp4',
      profile: '720p',
    },
  ] as OptimisedTrailer[],
  mubi_go_highlighted: false,
  content_warnings: someContentWarnings,
  portrait_image: '',
  episode: null,
  artworks: [],
  highlighted_industry_event_entry: {
    id: 268383,
    year: 2019,
    status: 'won',
    display_text: 'Jury Prize',
    full_display_text: '2019 | Winner: Jury Prize',
    industry_event: {
      id: 1,
      name: 'Cannes Film Festival',
      slug: 'cannes',
      type: 'festival',
      logo_url:
        'https://images.mubicdn.net/images/industry_event/1/cache-222790-1494527057/logo-small_black.png',
      white_logo_url:
        'https://images.mubicdn.net/images/industry_event/1/cache-222790-1494527057/logo-large_white.png',
      cover_url:
        'https://images.mubicdn.net/images/industry_event/1/cache-222788-1494406355/cover-original.jpg',
    },
  },
  award: null,
  press_quote: null,
  star_rating: null,
  cast_members_count: null,
  industry_events_count: null,
  comments_count: null,
};

export const yetAnotherFilm: Film = {
  id: 211276,
  slug: 'the-grand-bizarre',
  title: 'The Grand Bizarre',
  title_locale: 'en-US',
  original_title: 'The Grand Bizarre',
  year: 2018,
  consumable: null,
  duration: 61,
  stills: {
    small:
      'https://assets-staging.mubi.com/images/film/211276/image-w256.jpg?1586182893',
    medium:
      'https://assets-staging.mubi.com/images/film/211276/image-w448.jpg?1586182893',
    standard:
      'https://assets-staging.mubi.com/images/film/211276/image-w856.jpg?1586182893',
    retina:
      'https://assets-staging.mubi.com/images/film/211276/image-w1280.jpg?1586182893',
  },
  still_focal_point: {
    x: 0.491643,
    y: 0.487624,
  },
  hd: true,
  average_colour_hex: '727B8B',
  trailer_url:
    'https://trailers-dev.mubicdn.net/211276/t-the-grand-bizarre_en_us_1586249307.mp4',
  trailer_id: 47560,
  popularity: 615,
  web_url: 'https://mubi.localhost:3500/films/the-grand-bizarre',
  genres: ['Documentary'],
  average_rating: 3.8,
  average_rating_out_of_ten: 7.6,
  number_of_ratings: 3262,
  mubi_release: true,
  should_use_safe_still: false,
  still_url:
    'https://images.mubicdn.net/images/film/211276/cache-356129-1586182893/image-w1280.jpg',
  title_upcase: 'THE GRAND BIZARRE',
  critic_review_rating: 4.8182,
  content_rating: aNotRatedContentRating as ContentRating,
  short_synopsis:
    'A rapid-fire eye-popping and ear-pleasing study of textile patterns around the world. Filmed over five years, in fifteen countries, Mack places textiles against surprising backgrounds, editing the imagery to a homemade pop soundtrack.',
  short_synopsis_html:
    '<p>A rapid-fire eye-popping and ear-pleasing study of textile patterns around the world. Filmed over five years, in fifteen countries, Mack places textiles against surprising backgrounds, editing the imagery to a homemade pop soundtrack.</p>',
  default_editorial:
    'From MUBI regular Jodie Mack comes a tour de force, sui generis globetrotting textile documentary in the form of a 16mm abstract animated (and musical!) feature. An entrancing experience that is as much about the creation and circulation of fabric as it’s about the pure pleasure of color and design.',
  default_editorial_html:
    '<p>From MUBI regular Jodie Mack comes a tour de force, sui generis globetrotting textile documentary in the form of a 16mm abstract animated (and musical!) feature. An entrancing experience that is as much about the creation and circulation of fabric as it&#8217;s about the pure pleasure of color and design.</p>',
  title_treatment_url: null,
  directors: [
    {
      name: 'Jodie Mack',
      name_upcase: 'JODIE MACK',
      slug: 'jodie-mack',
    },
  ],
  historic_countries: ['United States'],
  optimised_trailers: [
    {
      url: 'https://trailers-dev.mubicdn.net/211276/optimised/240p-t-the-grand-bizarre_en_us_1586249307.mp4',
      profile: '240p',
    },
    {
      url: 'https://trailers-dev.mubicdn.net/211276/optimised/720p-t-the-grand-bizarre_en_us_1586249307.mp4',
      profile: '720p',
    },
  ] as OptimisedTrailer[],
  mubi_go_highlighted: false,
  content_warnings: [],
  portrait_image: '',
  episode: null,
  artworks: [],
  highlighted_industry_event_entry: null,
  award: null,
  press_quote: null,
  star_rating: null,
  cast_members_count: null,
  industry_events_count: null,
  comments_count: null,
};

export const aFourthFilm: Film = {
  id: 15446,
  slug: 'that-most-important-thing-love',
  title: 'That Most Important Thing: Love',
  title_locale: 'en-US',
  original_title: "L'important c'est d'aimer",
  year: 1975,
  consumable: null,
  duration: 109,
  stills: {
    small:
      'https://assets-staging.mubi.com/images/film/15446/image-w256.jpg?1635345291',
    medium:
      'https://assets-staging.mubi.com/images/film/15446/image-w448.jpg?1635345291',
    standard:
      'https://assets-staging.mubi.com/images/film/15446/image-w856.jpg?1635345291',
    retina:
      'https://assets-staging.mubi.com/images/film/15446/image-w1280.jpg?1635345291',
  },
  still_focal_point: {
    x: 0.713092,
    y: 0.532787,
  },
  hd: true,
  average_colour_hex: '080304',
  trailer_url:
    'https://trailers-dev.mubicdn.net/15446/t-that-most-important-thing-love_en_us_1837.654_1280_720_1635558032.mp4',
  trailer_id: 1048,
  popularity: 481,
  web_url: 'https://mubi.localhost:3500/films/that-most-important-thing-love',
  genres: ['Drama', 'Cult'],
  average_rating: 3.8,
  average_rating_out_of_ten: 7.6,
  number_of_ratings: 2167,
  mubi_release: false,
  should_use_safe_still: false,
  still_url:
    'https://images.mubicdn.net/images/film/15446/cache-12440-1635345291/image-w1280.jpg',
  title_upcase: 'THAT MOST IMPORTANT THING: LOVE',
  critic_review_rating: 4.9,
  content_rating: aContentRating as ContentRating,
  short_synopsis:
    'When an unhappy softcore actress (a would-be star who has only found work in cheap exploitation movies) becomes the obsession of a paparazzo, he proceeds to borrow money from his underworld employer to launch an experimental stage production for her—but it also stars a demented German actor.',
  short_synopsis_html:
    '<p>When an unhappy softcore actress (a would-be star who has only found work in cheap exploitation movies) becomes the obsession of a paparazzo, he proceeds to borrow money from his underworld employer to launch an experimental stage production for her&#8212;but it also stars a demented German actor.</p>',
  default_editorial:
    'After his 1972 film The Devil was banned in his homeland, the great Polish visionary Andrzej Żuławski decamped to France to make this frenzied tale of troubled passion in love and art. Driven with animal fervour by an unforgettable Romy Schneider, this moody drama won her the César for Best Actress.',
  default_editorial_html:
    '<p>After his 1972 film <em>The Devil</em> was banned in his homeland, the great Polish visionary Andrzej Żuławski decamped to France to make this frenzied tale of troubled passion in love and art. Driven with animal fervour by an unforgettable Romy Schneider, this moody drama won her the César for Best Actress.</p>',
  title_treatment_url: null,
  directors: [
    {
      name: 'Andrzej Żuławski',
      name_upcase: 'ANDRZEJ ŻUŁAWSKI',
      slug: 'andrzej-zulawski',
    },
  ],
  historic_countries: ['France', 'Italy'],
  optimised_trailers: [
    {
      url: 'https://trailers-dev.mubicdn.net/15446/optimised/240p-t-that-most-important-thing-love_en_us_1837.654_1280_720_1635558032.mp4',
      profile: '240p',
    },
    {
      url: 'https://trailers-dev.mubicdn.net/15446/optimised/720p-t-that-most-important-thing-love_en_us_1837.654_1280_720_1635558032.mp4',
      profile: '720p',
    },
  ] as OptimisedTrailer[],
  mubi_go_highlighted: false,
  content_warnings: someContentWarnings,
  portrait_image: '',
  episode: null,
  artworks: [],
  highlighted_industry_event_entry: null,
  award: null,
  press_quote: null,
  star_rating: null,
  cast_members_count: null,
  industry_events_count: null,
  comments_count: null,
};

export const aFilmWithALongTitle: Film = {
  id: 202089,
  slug: 'the-violence-of-a-civilization-without-secrets',
  title: 'The Violence of a Civilization without Secrets',
  title_locale: 'en-US',
  original_title: 'The Violence of a Civilization without Secrets',
  year: 2017,
  consumable: null,
  duration: 10,
  stills: {
    small:
      'https://assets-staging.mubi.com/images/film/202089/image-w256.jpg?1618329170',
    medium:
      'https://assets-staging.mubi.com/images/film/202089/image-w448.jpg?1618329170',
    standard:
      'https://assets-staging.mubi.com/images/film/202089/image-w856.jpg?1618329170',
    retina:
      'https://assets-staging.mubi.com/images/film/202089/image-w1280.jpg?1618329170',
  },
  still_focal_point: {
    x: 0.458134,
    y: 0.190517,
  },
  hd: true,
  average_colour_hex: '615435',
  trailer_url:
    'https://trailers-dev.mubicdn.net/202089/t-the-violence-of-a-civilization-without-secrets_en_us_1612800465.mp4',
  trailer_id: 54127,
  popularity: 129,
  web_url:
    'https://mubi.localhost:3500/films/the-violence-of-a-civilization-without-secrets',
  genres: ['Documentary', 'Short'],
  average_rating: 4.0,
  average_rating_out_of_ten: 7.9,
  number_of_ratings: 564,
  mubi_release: false,
  should_use_safe_still: false,
  still_url:
    'https://images.mubicdn.net/images/film/202089/cache-325974-1618329170/image-w1280.jpg',
  title_upcase: 'THE VIOLENCE OF A CIVILIZATION WITHOUT SECRETS',
  critic_review_rating: 0.0,
  content_rating: aContentRating as ContentRating,
  short_synopsis:
    'An urgent reflection on indigenous sovereignty, the undead violence of museum archives, and postmortem justice through the case of the “Kennewick Man,” a prehistoric Paleo-American man whose remains were found in Kennewick, Washington, in 1996.',
  short_synopsis_html:
    '<p>An urgent reflection on indigenous sovereignty, the undead violence of museum archives, and postmortem justice through the case of the &#8220;Kennewick Man,&#8221; a prehistoric Paleo-American man whose remains were found in Kennewick, Washington, in 1996.</p>',
  default_editorial:
    'Mixing archival footage, CGI, and performance art, this striking short is a reproach to the institutional legitimization of white supremacy. The film questions the scientific authority to determine fact and evidence by examining the anthropological tendency to treat human remains as artefacts.',
  default_editorial_html:
    '<p>Mixing archival footage, CGI, and performance art, this striking short is a reproach to the institutional legitimization of white supremacy. The film questions the scientific authority to determine fact and evidence by examining the anthropological tendency to treat human remains as artefacts.</p>',
  title_treatment_url: null,
  directors: [
    {
      name: 'Adam Khalil',
      name_upcase: 'ADAM KHALIL',
      slug: 'adam-khalil',
    },
    {
      name: 'Zack Khalil',
      name_upcase: 'ZACK KHALIL',
      slug: 'zack-khalil',
    },
    {
      name: 'Jackson Polys',
      name_upcase: 'JACKSON POLYS',
      slug: 'jackson-polys',
    },
  ],
  historic_countries: ['United States'],
  optimised_trailers: [
    {
      url: 'https://trailers-dev.mubicdn.net/202089/optimised/240p-t-the-violence-of-a-civilization-without-secrets_en_us_1612800465.mp4',
      profile: '240p',
    },
    {
      url: 'https://trailers-dev.mubicdn.net/202089/optimised/720p-t-the-violence-of-a-civilization-without-secrets_en_us_1612800465.mp4',
      profile: '720p',
    },
    {
      url: 'https://trailers-dev.mubicdn.net/202089/optimised/1080p-t-the-violence-of-a-civilization-without-secrets_en_us_1612800465.mp4',
      profile: '1080p',
    },
  ] as OptimisedTrailer[],
  mubi_go_highlighted: false,
  content_warnings: someContentWarnings,
  portrait_image: '',
  episode: null,
  artworks: [],
  highlighted_industry_event_entry: null,
  award: null,
  press_quote: null,
  star_rating: null,
  cast_members_count: null,
  industry_events_count: null,
  comments_count: null,
};

export const someFilms = [aFilm, anotherFilm, yetAnotherFilm, aFourthFilm];

export const someFilmsKeyedByFilmId = {
  3701: aFilm,
  226295: anotherFilm,
  211276: yetAnotherFilm,
  15446: aFourthFilm,
};

export const someFilmsSlugToIdLookup = {
  [aFilm.slug]: 3701,
  [anotherFilm.slug]: 226295,
  [yetAnotherFilm.slug]: 211276,
  [aFourthFilm.slug]: 15446,
};

export const aFilmWithAward = {
  ...aFilm,
  award: {
    prize_image_url:
      'https://images.mubicdn.net/images/carousel_takeover/196/cache-912037-1696947020/prize_image_en_us-original.png',
    prize_text: 'Cannes Laurel, Out of Competition, 2023 Official Selection',
  },
};

export const aFilmWithRating = {
  ...aFilm,
  star_rating: {
    star_rating: 4,
    source: 'Total Film',
  },
};

export const aFilmWithPressQuote = {
  ...aFilm,
  press_quote: {
    quote: 'Surreal, stylish, strange and startlingly unique',
    source: 'Empire',
  },
};

export const aFilmWithAwardAndIndustryEvent = {
  ...aFilm,
  award: {
    prize_image_url:
      'https://images.mubicdn.net/images/carousel_takeover/196/cache-912037-1696947020/prize_image_en_us-original.png',
    prize_text: 'Cannes Laurel, Out of Competition, 2023 Official Selection',
  },
  highlighted_industry_event_entry: anIndustryEventEntry,
};

export const aFilmWithTitleTreatment: Film = {
  ...aFilm,
  title_treatment_url:
    'https://images.mubicdn.net/images/artworks/703004/cache-703004-1727363594/images-original.png',
};

export const aFilmWithAbsurdlyLongFilmTitle: Film = {
  ...aFilm,
  title_upcase:
    'Night of the Day of the Dawn of the Son of the Bride of the Return of the Revenge of the Terror of the Attack of the Evil, Mutant, Hellbound, Flesh-Eating Subhumanoid Zombified Living Dead, Part 2',
};

export const aFilmWithAbsurdlyLongWordInFilmTitle: Film = {
  ...aFilm,
  title_upcase: 'FRAUEN AM RANDE DES NERVENZUSAMMENBRUCHS',
};

export const featuredFilm = {
  id: 226295,
  title: 'Bacurau',
  slug: 'bacurau',
  year: 2019,
  type: 'film',
  historic_countries: ['Brazil'],
  average_color_hex: '6F5449',
  image: `${imagesDomain}/images/film/226295/cache-403714-1584616206/image-w1280.jpg`,
  directors: [
    {
      name: 'Kleber Mendonça Filho',
      slug: 'kleber-mendonca-filho',
    },
    {
      name: 'Juliano Dornelles',
      slug: 'juliano-dornelles',
    },
  ],
};

export const specialFilm = {
  id: 336,
  title: 'Brief Encounters',
  slug: 'brief-encounters',
  color: '24c7ae',
  description:
    'New short films by some of the best up-and-coming as well as established filmmakers and artists.',
  description_html:
    '<p>New short films by some of the best up-and-coming as well as established filmmakers and artists.</p>',
};

export const anEpisode = {
  ...aFilm,
  episode: {
    label: 'Episode 1',
    film_group_id: 1,
    number: 1,
    season_number: 1,
    season_title: 'Season 1',
    series_title: 'A series',
    fotd_show_episode: false,
    episode_label_color: 'ffffff',
    episode_title: 'Episode Title',
  },
};

export const getRandomFilmWithRandomId = () => {
  const randomFilm = someFilms[Math.floor(Math.random() * someFilms.length)];
  return Object.assign({}, randomFilm, { id: uuidv4() });
};

export const getAFilmWithTitle = title => {
  const filmCopy = { ...aFilm };
  filmCopy.title = title;
  filmCopy.title_upcase = title.toUpperCase();
  return filmCopy;
};

export const someFilmIds = someFilms.map(aFilm => aFilm.id);

export const generateFilmWithConsumable = <FilmInputAndOutput extends Film>(
  film: FilmInputAndOutput,
  currentOrExpiredOrFuture?: ConsumableDateLabel,
  types?: ConsumableType,
): FilmInputAndOutput => ({
  ...film,
  consumable: generateConsumable(currentOrExpiredOrFuture, types, film.id),
});
